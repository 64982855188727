<template>
  <div class="page-wrapper">
    <slot/>
  </div>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
.page-wrapper {
  min-height: calc(100vh - var(--Nav-Height));
}
</style>